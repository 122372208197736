import "./Hero.css";
import { Link } from "react-router-dom";
import { FaFacebookF, FaPlay, FaPause, FaMapMarkerAlt } from "react-icons/fa";

import heroVideo from "../../assets/videos/horizonshowroomEdit_c.mp4"
import ReactPlayer from "react-player";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation()
  const [playing, setPlaying] = useState(false)
  const videoPlayBtn = useRef()
  const handlePlay = () => {
    !playing ? videoPlayBtn.current.style.opacity = .3 : videoPlayBtn.current.style.opacity = 1
    setPlaying(!playing)
  }
  return (
    <div className='container hero hero-c'>
      <div className="heroContent">
        <h6 className="heroTextSub">{t("heroTextSub")}</h6>
        <div className="heroTexts">
          <h1 className="heroTitle">{t("heroTitle")}</h1>
          <h6 className='heroText_3' >{t("heroText_3", { returnObjects: true }).line_1}</h6>
          <h6 className="heroText_3" style={{color: "var(--secondary-gold)"}}><FaMapMarkerAlt /> {t("heroText_3", { returnObjects: true }).line_2}</h6>
        </div>
        <div className="heroContact_c">
          <Link target="_blank" to={"https://www.facebook.com/Horizondekorasyon"} className="readBtn btn d-btn">
            <div className="social_c">
              <FaFacebookF size={25} />
              <p className="heroBtnText">Facebook</p>
            </div>
          </Link>
          <div className="heroContact">
            <a href="tel:+90 537 738 73 99" className="phoneN">{t("phoneN")}</a>
          </div>
        </div>
      </div>
      <div className="heroVideo_c">
        {/* <div className="videoPlayBg"></div> */}
        <div ref={videoPlayBtn} onClick={handlePlay} className="videoPlayBtn">
          {!playing ? <FaPlay /> : <FaPause />}
        </div>
        <ReactPlayer
          width={"100%"}
          height={"auto"}
          url={heroVideo}
          playing={playing}
          controls={true}
        />
      </div>
    </div>
  )
}

export default Hero